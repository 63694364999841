import moment from 'moment'

export default function formatDate(dt) {
  if (dt) {
    // return '17 minutes ago'
    // return moment(dt, 'HH:MM:SS').format('MM/DD/YYYY hh:mm')
    const a = moment(dt)
    const b = moment(new Date())
    const minutes = b.diff(a, 'minutes')
    if (minutes > 60) {
      if (minutes > 24 * 60) {
        if (minutes > 3 * 24 * 60) {
          return a.format('MM/DD/YYYY hh:mm')
        }

        const val = Math.round(minutes / (24 * 60))
        return `${val} days ago`
      }

      const val = Math.round(minutes / 60)
      return `${val} hours ago`
    }

    return `${minutes} minutes ago`
  }

  return ''
}
